import { dehydrate, type QueryClient } from '@tanstack/react-query';

export interface ServerQuery {
  queryKey: unknown[];
  queryFn: Function;
}

/**
 *  Fetches required react-query requests on the server
 *  #### Example request:
 *  queryOnServer([{
 *    queryKey: ["site-footer"],
 *    queryFn: getFooterTemplate
 *  }])
 *
 * @param queries
 * @param queryClient
 * @returns `Promise<DehydratedState>`
 */
export async function queryOnServer(queries: ServerQuery[], queryClient: QueryClient) {
  const results = await Promise.allSettled(
    queries.map(async ({ queryKey, queryFn }) => {
      // @ts-expect-error: queryFn is a function, as typed in `ServerQuery`
      return queryClient.prefetchQuery(queryKey, queryFn, { staleTime: 60000 });
    }),
  );

  results.forEach((result, index) => {
    if (result.status === 'rejected') {
      console.error(`Error prefetching query: ${queries[index].queryKey}`, result.reason);
    }
  });

  return dehydrate(queryClient);
}
